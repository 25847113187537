.container {
  width: 300px;
  display: block;
}

.image {
  width: 300px;
  height: 240px;
  border-radius: 6px;
  margin-bottom: 12px;
  overflow: hidden;
}

.dash {
  width: 10px;
  height: 10px;
  display: inline-block;
  @apply mx-0.5;
}

.star {
  @apply mr-1 inline-block;
}
